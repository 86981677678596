/* eslint-disable camelcase */
import { useEffect, useMemo, useRef } from 'react'
import { VisuallyHidden, useIsVisible } from '@overdose/components'
import classNames from 'classnames'
import Link from 'next/link'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import { useAlgoliaSearchInsignts } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { useProductDataLayer } from '~/lib/gtm/hooks'
import { Button } from '../Button'
import RichText from '../RichText/RichText'
import { SalePrice } from '../SalePrice'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './OfferCard.module.css'
import { OfferCardProps } from './OfferCard.types'
import { OfferImage } from './OfferImage'
import { OfferSticker } from './OfferSticker'

export const OfferCard = ({
  backgroundColor,
  size = 'large',
  label,
  title,
  titleText,
  description,
  shopNow,
  image,
  imagePosition = 'cover',
  brandLogo,
  disclaimer,
  skus,
  sticker,
  price,
  saveText,
  wasText,
  brandName,
  category1Name,
  category2Name,
  category3Name,
  category4Name,
  category5Name,
  listId,
  listName,
  item_status_tags,
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  productId,
}: OfferCardProps) => {
  const isLarge = size === 'large'

  const priceStyles = classNames('mt-4 w-max max-lg:h-6', styles.salesPrice, {
    'lg:mt-8 md:mt-4': isLarge,
    [styles.largeSalesPrice]: isLarge,
    'md:mt-4': !isLarge,
    [styles.smallSalesPrice]: !isLarge,
  })

  const { dispatchSelectPromotionDataLayerEvent } = useProductDataLayer()
  const { sendClickEvent } = useAlgoliaSearchInsignts()

  const product = useMemo(() => {
    const url = shopNow?.shopNow?.href || ''
    const sku = skus?.[0]?.sku
    const title = titleText
    const hasRequiredItemFields = sku || title
    if (!url.includes('/p/') || !hasRequiredItemFields) {
      return null
    }

    return {
      sku,
      title,
      finalPrice: price?.regular?.centAmount ? price.regular : price.final,
      brandName,
      category1Name,
      category2Name,
      category3Name,
      category4Name,
      category5Name,
      listId,
      listName,
      item_status_tags,
      index: /^\d+$/.test(creativeSlot) ? Number(creativeSlot) : 0,
    }
  }, [
    skus,
    titleText,
    price,
    brandName,
    category1Name,
    category2Name,
    category3Name,
    category4Name,
    category5Name,
    listId,
    listName,
    item_status_tags,
    creativeSlot,
    shopNow,
  ])
  const isPromotionOfferCard =
    !!promotionId && !!promotionName && !!creativeName && !!creativeSlot

  const ref = useRef()
  const isVisible = useIsVisible(ref)

  useEffect(() => {
    const payload = {
      promotionId,
      promotionName,
      creativeSlot,
      creativeName,
      product,
    }

    if (isVisible && isPromotionOfferCard) {
      GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
      GTM.dispatch(GTMEvent.VIEW_PROMOTION, payload)
    }
  }, [
    isPromotionOfferCard,
    isVisible,
    product,
    promotionId,
    promotionName,
    creativeName,
    creativeSlot,
  ])

  return (
    <div
      className={classNames(
        'relative p-4 text-white rounded-xl flex flex-col w-full h-full overflow-clip',
        {
          [styles.root]: !backgroundColor,
          '2xl:p-12 md:p-6': isLarge,
          'md:p-6': !isLarge, // Small Variant
        }
      )}
      style={{ backgroundColor }}
      ref={ref}>
      {!!skus?.length && (
        <div
          className={classNames(
            'flex flex-col gap-1 absolute z-20 top-4 right-4 text-greyscale-50',
            {
              '2xl:top-12 2xl:right-12 md:top-6 md:right-6': isLarge,
              'md:top-6 md:right-6': !isLarge,
            }
          )}>
          {skus.map(({ url, sku, title }) => {
            return (
              <Link key={sku} href={url} title={title || ''}>
                <Typography
                  tag={TypographyTag.span}
                  variant={TypographyVariant.BodySmall}
                  className={classNames('block', styles.sku)}>
                  {sku}
                </Typography>
                <VisuallyHidden>{title}</VisuallyHidden>
              </Link>
            )
          })}
        </div>
      )}

      {/* Card Header */}
      <div className={classNames('flex gap-4 justify-between z-10')}>
        <div
          className={classNames('flex flex-col gap-1.5 w-full', {
            'lg:gap-4 md:gap-2.5': isLarge,
            'md:gap-2.5': !isLarge, // Small Variant
          })}>
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegularBold}
            className={classNames(
              'text-greyscale-50 uppercase !font-bold',
              styles.label,
              {
                [styles.smallLabel]: !isLarge,
                [styles.largeLabel]: isLarge,
              }
            )}>
            {label}
          </Typography>

          {/* Rich Text Content */}
          {(title || description) && (
            <div className={styles.heading}>
              {title && (
                <div
                  className={classNames(styles.title, {
                    [styles.largeTitle]: isLarge,
                    [styles.smallTitle]: !isLarge,
                  })}>
                  <RichText content={title} />
                </div>
              )}
              {description && (
                <div
                  className={classNames(
                    'hidden md:block',
                    'mt-1.5',
                    styles.description,
                    {
                      'lg:mt-4 md:mt-2.5': isLarge,
                      [styles.largeDescription]: isLarge,
                      'md:mt-2.5': !isLarge, // Small Variant
                      [styles.smallDescription]: !isLarge,
                    }
                  )}>
                  <RichText content={description} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className='flex flex-1 justify-between'>
        <div className='flex flex-col w-max z-10'>
          <SalePrice
            price={price}
            saveText={saveText?.saveText}
            wasText={wasText}
            regularPriceClassName={priceStyles}
            finalPriceClassName={priceStyles}
            savePriceClassName={classNames(
              'mt-2 py-1.5 md:py-3 flex justify-center rounded uppercase !font-bold',
              styles.savePrice,
              {
                'lg:mt-6 md:mt-3': isLarge,
                'md:mt-3': !isLarge,
                [styles.largeSavePrice]: isLarge,
                [styles.smallSavePrice]: !isLarge,
              }
            )}
            wrapperClassName='block'
            regularPriceTypographyVariant={TypographyVariant.BodyXLarge}
            savePriceTypographyVariant={TypographyVariant.BodyLargeBold}
            saveUpToButtonStyle={saveText?.saveUptoButtonStyle}
            truncateDecimalZeros
          />

          {shopNow?.shopNow?.href && (
            <Button
              fluid
              className={classNames(
                'mt-2 max-md:text-sm max-md:leading-4',
                styles.shopNow,
                {
                  'lg:mt-3 md:mt-2.5': isLarge,
                  'md:mt-2.5': !isLarge,
                }
              )}
              size='lg'
              style={{
                backgroundColor: shopNow.buttonColor || '#FFFFFF',
                color: shopNow.buttonTextColor || '#0C254C',
              }}>
              {shopNow?.shopNow?.title || 'Shop Now'}
            </Button>
          )}

          <div className={classNames('flex-1 flex flex-col justify-end')}>
            {brandLogo?.altText && brandLogo?.src && (
              <Image
                alt={brandLogo?.altText}
                src={getImageSrc(brandLogo?.src, '100')}
                addSrcSet={false}
                width={brandLogo?.width}
                height={brandLogo?.height}
                className='object-contain max-md:w-20 max-md:h-5 mt-5 md:mt-2 shrink-0'
                style={{
                  width: brandLogo?.width || '100%',
                  height: brandLogo?.height || '100%',
                }}
              />
            )}

            {disclaimer && (
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodyRegular}
                className={classNames(
                  styles.disclaimer,
                  'mt-2 md:mt-3 text-greyscale-50',
                  {
                    [styles.smallDisclaimer]: !isLarge,
                    [styles.largeDisclaimer]: isLarge,
                    'mt-5': !brandLogo?.src,
                  }
                )}>
                *{disclaimer}
              </Typography>
            )}
          </div>
        </div>

        {/* Product Image */}
        <OfferImage image={image} imagePosition={imagePosition} />
      </div>

      {/* Sticker */}
      {sticker?.content && <OfferSticker isLarge={isLarge} sticker={sticker} />}

      {/* Overlay Link */}
      {shopNow?.shopNow?.href && (
        <Link
          href={shopNow.shopNow.href}
          title={shopNow.shopNow.title}
          target={shopNow.openInNewWindow ? '_blank' : ''}
          className='absolute inset-0 z-10'
          onClick={() => {
            isPromotionOfferCard &&
              dispatchSelectPromotionDataLayerEvent({
                promotionId,
                promotionName,
                creativeSlot,
                creativeName,
                product,
              } as never)
            sendClickEvent([
              {
                title: shopNow.shopNow.title,
                url: shopNow.shopNow.href,
                listId: productId,
              },
            ])
          }}>
          <VisuallyHidden>{shopNow.shopNow.title}</VisuallyHidden>
        </Link>
      )}
    </div>
  )
}
